import React from "react"
import {
  Container,
  HeroImage,
  Main,
  Cutin,
  Content,
  ContentCard,
  Contact,
  Iframely,
  Seo,
  Button,
} from "../components"
import { FixedPageH1, FixedPageH2, FixedPageH4 } from "../elements"
import { Link, graphql } from "gatsby"

const IndexPage = ({ data }) => {
  return (
    <Container>
      <Seo />
      <Iframely />
      <HeroImage />
      <Cutin>
        <Main>
          <FixedPageH1>
            <h1>
              漆のマルスエ
              <span>お仏壇・仏具製造販売</span>
              <span>山車・御神輿・日本刀</span>
              <span>漆塗り・金箔・蒔絵</span>
            </h1>
          </FixedPageH1>
          <Content>
            <FixedPageH2>About</FixedPageH2>
            <ContentCard
              fixed={data.sandaimeFixed.childImageSharp.fixed}
              title="どんなお店？"
              cover="会社概要"
            >
              <div>
                <Button href="/profile/">どんなお店？ページへ</Button>
              </div>
            </ContentCard>
          </Content>
          <Content>
            <FixedPageH2>Works</FixedPageH2>
            <ContentCard
              fixed={data.kobutsuzenshu.childImageSharp.fixed}
              title="お仏壇のページ"
              cover="お仏壇"
            >
              <ul>
                <li>
                  <FixedPageH4>
                    <Link to="/marusue-b/#service">サービス一覧</Link>
                  </FixedPageH4>
                  <ul>
                    <li>
                      <Link to="/marusue-b-commitment/">
                        新しくお仏壇を造る
                      </Link>
                    </li>
                    <li>
                      <Link to="/marusue-b-osentaku/">お仏壇の修復</Link>
                    </li>
                    <li>
                      <Link to="/obutsudan-idou-shobun/">
                        お仏壇移動・処分・お預かり
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <FixedPageH4>
                    <Link to="/marusue-b/#yondaime-blog">お仏壇情報ブログ</Link>
                  </FixedPageH4>
                  <ul>
                    <li>
                      <Link to="/marusue-b/#001">
                        プロが解説！お仏壇の選び方
                      </Link>
                    </li>
                    <li>
                      <Link to="/marusue-b/#002">お仏壇を直す方法まとめ</Link>
                    </li>
                    <li>
                      <Link to="/marusue-b/#003">
                        お仏壇・仏具メンテナンス情報
                      </Link>
                    </li>
                    <li>
                      <Link to="/marusue-b/#004">
                        移動・処分方法　DIY方法も解説
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
              <div>
                <Button href="/marusue-b/">お仏壇ページへ</Button>
              </div>
            </ContentCard>
            <ContentCard
              fixed={data.ishitori.childImageSharp.fixed}
              title="祭車の漆塗り"
              cover="山車"
              cover2="御神輿"
              href="//"
            >
              <ul>
                <li>
                  <FixedPageH4>
                    <Link to="/float/#service">サービス一覧</Link>
                  </FixedPageH4>
                  <ul>
                    <li>
                      <Link to="/marusue-dashi-urushi/">
                        山車の塗り・塗替え
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <FixedPageH4>
                    <Link to="/float/#yondaime-blog">
                      お祭り塗師の情報ブログ
                    </Link>
                  </FixedPageH4>
                  <ul>
                    <li>
                      <Link to="/dashi-paint/">
                        山車の塗り・塗替えガイドライン
                      </Link>
                    </li>
                    <li>
                      <Link to="/omikoshi-paint/">
                        御神輿の塗り・塗替えガイドライン
                      </Link>
                    </li>
                    <li>
                      <Link to="/dashi-omikoshi/">
                        山車と御神輿の違いとは？
                      </Link>
                    </li>
                    <li>
                      <Link to="/dashi-soushoku/">山車の装飾とは？</Link>
                    </li>
                  </ul>
                </li>
              </ul>
              <div>
                <Button href="/float/">山車・御神輿ページへ</Button>
              </div>
            </ContentCard>
          </Content>
          <Content>
            <FixedPageH2>Gallery</FixedPageH2>
            <ContentCard
              fixed={data.urushipresso.childImageSharp.fixed}
              title="制作実績"
              cover="Gallery"
              page="制作実績"
              href="/gallery/"
            ></ContentCard>
          </Content>
          {/* <ContentCard
              fixed={data.saya2.childImageSharp.fixed}
              title="日本刀"
              cover="Sword"
              page="日本刀"
              href="/japanese-sword/"
            >
              <ul>
                <li className="yet">準備中</li>
              </ul>
            </ContentCard>
            <ContentCard
              fixed={data.urushipresso.childImageSharp.fixed}
              title="伝統工芸"
              cover="Others"
              page="伝統工芸"
              href="/others/"
            >
              <ul>
                <li className="yet">準備中</li>
              </ul>
            </ContentCard>  */}
          <Content>
            <FixedPageH2>お問い合わせ</FixedPageH2>
            <Contact />
            <div className="iframely-embed">
              <div
                className="iframely-responsive"
                style={{ height: 140, paddingBottom: 0 }}
              >
                <a
                  href="https://www.google.com/maps/search/?api=1&query=%E3%83%9E%E3%83%AB%E3%82%B9%E3%82%A8%E4%BD%9B%E5%A3%87"
                  data-iframely-url="//cdn.iframe.ly/api/iframe?url=https%3A%2F%2Fwww.google.com%2Fmaps%2Fsearch%2F%3Fapi%3D1%26query%3D%E3%83%9E%E3%83%AB%E3%82%B9%E3%82%A8%E4%BD%9B%E5%A3%87&key=bbb152fda61816746702349ee0e12e2b"
                  aria-label="GoogleMaps"
                >
                  {null}
                </a>
              </div>
            </div>
          </Content>
        </Main>
      </Cutin>
    </Container>
  )
}

export const query = graphql`
  query {
    kobutsuzenshu: file(relativePath: { eq: "kobutsuzenshu.jpg" }) {
      childImageSharp {
        fixed(width: 800, quality: 30) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    ishitori: file(relativePath: { eq: "ishitori.jpg" }) {
      childImageSharp {
        fixed(width: 800, quality: 30) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    saya2: file(relativePath: { eq: "saya2.jpg" }) {
      childImageSharp {
        fixed(width: 800, quality: 30) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    urushipresso: file(relativePath: { eq: "urushipresso.jpg" }) {
      childImageSharp {
        fixed(width: 800, quality: 30) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    sandaimeFixed: file(relativePath: { eq: "sandaimeFixed.jpg" }) {
      childImageSharp {
        fixed(width: 800, quality: 30) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`

export default IndexPage
